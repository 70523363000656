/**
 * Terms of Service page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Sectionh1content from "../components/sectionh1content"
import Seo from "../components/seo"
import "../styles/pages/terms-of-service.scss"

/* Page function declaration */
const Termsofservicev1Page = ({ data }) => {
  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageTermsmembership

  //CTA Footer page FAQ
  var ctaFooterFAQ= data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "faq"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        noindex="noindex"
      />

      {/* Section Hero */}
      <Hero colorShape="grey" classNamePage="general" />

      {/* Section H1 */}
      <Sectionh1content classNameH1="terms-service-h1" titleH1={dataAcf.titleGeneralTermsService} />

      {/* Main content */}
      <section className="main-section grey terms-service">
        <div className="content-general text" dangerouslySetInnerHTML={{ __html:dataPage.content}} />
        <div className="content-general text content-terms">
          <h2>I. {dataAcf.titleSection1}</h2>
          <div className="text" dangerouslySetInnerHTML={{ __html:dataAcf.contentSection1}} />
        </div>
        <div className="content-general text content-terms">
          <h2>II. {dataAcf.titleSection2}</h2>
          <div className="text" dangerouslySetInnerHTML={{ __html:dataAcf.contentSection2}} />
        </div>
        <div className="content-general text content-terms">
          <h2>III. {dataAcf.titleSection3}</h2>
          <div className="text" dangerouslySetInnerHTML={{ __html:dataAcf.contentSection3}} />
        </div>
      </section>
      {/* Section CtaFooter */}
      {ctaFooterFAQ.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="faq" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="grey" />
      })}
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "membership-terms-of-service"}) {
      content
      acfPageTermsmembership {
        contentSection1
        contentSection2
        contentSection3
        titleGeneralTermsService
        titleSection1
        titleSection2
        titleSection3
      }
      seo {
        metaDesc
        title
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`

/* Export page informations */
export default Termsofservicev1Page
